@use "sass:math";

.l-header {
  margin-bottom: space(lg);

  h1 {
    margin: 0;
  }

  p {
    margin: space(base) 0 0;
    font-size: modular-scale(-1);
    line-height: 1.3;
  }

  &--announcer {
    display: flex;
    position: relative;
    align-items: center;
    height: 175px;
    margin-bottom: 0;
    padding-right: space(lg);
    background-color: $gray;
    color: $white;
    overflow: hidden;

    &::after {
      content: '';
      z-index: 2;
      display: block;
      @include size(100px, 100%);
      @include position(absolute, 0 200px null null);
    }

    h1 {
      z-index: 3;
      position: relative;
      margin-right: 200px;
    }

    > .l-container > img {
      z-index: 1;
      @include position(absolute, 0 -30px null null);
    }

    &.l-header--collection-arcand {
      background-color: #3f4542;

      &::after {
        right: 165px;
        background: linear-gradient(90deg, #3f4542 0%, rgba(#3f4542, 0) 100%);
      }

      > .l-container > img {
        margin-right: -15px;
      }
    }

    &.l-header--collection-balado-cadeau {
      background: url('https://cdn.cogecolive.com/websites-public/Background_BaladoCadeau_Transparent_241204.png'), radial-gradient(#062d59, #000000);
      background-position: right center;
      background-repeat: no-repeat;
    }

    &.l-header--collection-bonmatch {
      background-color: #030f35;

      &::after {
        right: 165px;
        background: linear-gradient(90deg, #030f35 0%, rgba(#030f35, 0) 100%);
      }

      > .l-container > img {
        margin-right: -15px;
      }
    }
  }

  &--podcast {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    padding: space(lg);
    background-color: $black;
    color: $white;

    @media (min-width: $bp-md) {
      flex-direction: row;
    }

    .background {
      z-index: 1;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.3;
      filter: blur(24px);
      @include position(absolute, 0);
    }

    h2 {
      color: rgba($white, 0.8);
      font-size: modular-scale(1);
    }

    .l-header__sponsor {
      font-size: modular-scale(-3);

      span {
        display: block;
      }
    }

    .o-btn-list {
      margin-top: space(lg);
    }

    .c-btn {
      padding: space(sm) space(md);
      background-color: rgba($white, 0.25);
      color: $white;
      font-size: modular-scale(-1);
      margin: 0 space(sm) space(sm) 0 !important;
    }

    .l-header__data {
      order: 1;

      @media (min-width: $bp-md) {
        order: 0;
      }
    }

    .l-header__data + img {
      position: relative;
      z-index: 2;
      order: 0;
      flex: 0 0 160px;
      margin-right: space(lg);
      margin-bottom: space(lg);
      border-radius: 4px;
    }
  }

  &__data {

    .l-header--podcast & {
      position: relative;
      flex: 1 1 calc(100% - 180px);
      margin-bottom: space(md);
      z-index: 2;
    }

    h1 {
      margin-bottom: space(base);
    }
  }
}
