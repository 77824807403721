.c-alert {
  padding: space(md);
  border-radius: 0.25rem;
  color: $white;
  font-size: 1.1em;
  font-weight: 700;
  text-align: center;

  &--success {
    background-color: palette(ui, success);
  }

  &--error {
    background-color: palette(ui, error);
  }

  p {
    margin: 0;
  }

  svg {
    margin-bottom: space(sm);
  }
}
