.l-show {

  .schedule {
    margin: 0;
    color: palette(gray, light);
  }

  .description {
    line-height: 1.3;
  }

  &__header {
    position: relative;

    &::after {
      z-index: 1;
      display: block;
      height: 35%;
      background-image: linear-gradient(
        -180deg,
        rgba(palette(black, dark), 0) 0%,
        rgba(palette(black, dark), 0.5) 100%
      );
      content: '';
      @include position(absolute, null 0 0 0);
    }

    .info {
      z-index: 2;
      margin: 0;
      padding: space(lg);
      color: $white;
      font-size: modular-scale(-1);
      line-height: 1.1;
      @include position(absolute, null null 0 0);

      span {
        display: block;
        margin-top: 0.25em;
      }
    }

    .c-btn {
      border: 0;
      @include position(absolute, space(base) space(base) null null);

      svg {
        filter: drop-shadow(0px 0px 3px rgba($black, .5));
      }
    }
  }

  &__announcers {
    margin-bottom: space(lg);
    padding-bottom: space(base);
    border-bottom: 1px solid palette(gray, x-light);

    h5 {
      margin: 0 0 space(md);
      font-weight: 700;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      @include list-unstyled;

      a,
      > li {
        display: flex;
        align-items: center;
        margin-bottom: space(sm);
      }

      a {
        text-decoration: none;
      }

      li {
        flex: 1 1 50%;
      }

      .user-profile-image {
        position: relative;
        overflow: hidden;
        margin-right: space(base);
        @include size(2rem);

        img {
          width: auto;
          height: 32px;
          transform: translateX(-50%);
          @include position(absolute, null null null 50%);
        }
      }

      span {
        @include ellipsis;
      }
    }
  }
}
