.o-block {
  & + & {
    margin-top: space(lg);
  }

  &--inversed {
    color: $white;
    background-color: $gray;
  }

  &--center {
    text-align: center;
  }

  &--loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: palette(gray, light);
    text-align: center;
    @include position(fixed, 0);

    p {
      margin: 1rem 0 0;
      font-size: 0.7rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  &--404 {
    padding: space(lg);

    h1,
    h2 {
      color: palette(gray, light);
      text-align: center;
    }

    h1 {
      margin: 0;
      font-size: modular-scale(8);
    }

    li {
      margin-bottom: 0.5em;
    }
  }

  &--select {
    position: relative;

    .icon {
      transform: translateY(-50%);
      color: palette(gray);
      @include position(absolute, 50% 0.75rem null null);
    }
  }

  &__header {
    margin-bottom: 1rem;

    &--date {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    p {
      margin: 0.5em 0 0;
      color: palette(gray);
    }
  }

  &__date {
    color: palette(gray);
  }
}
