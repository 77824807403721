.c-form {
  width: 90%;
  max-width: 600px;
  margin: space(xl) auto;

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  legend {
    margin: 0;
  }

  &__group {
    position: relative;
    margin: space(md) 0;
  }

  &__label {
    display: block;
    margin-bottom: space(sm);
    transition: top 0.2s, left 0.2s, font-size 0.2s;
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: 0.02em;
    cursor: text;
    // @include position(absolute, 20px null null 10px);
  }

  &__input,
  &__textarea {
    display: block;
    width: 100%;
    padding: 0.3rem 0.5rem;
    border: 2px solid palette(gray, x-light);
    border-radius: 0.25em;
    outline: none;
    background-color: $white;
    color: $black;
    font-family: font-family(text);
    font-size: 1rem;
    appearance: none;

    &:focus {
      border-color: palette(grey, light);
    }

    &.ng-invalid.ng-touched {
      border-color: palette(ui, error);
    }
  }

  &__textarea {
    min-height: 200px;
    overflow: auto;
    resize: vertical;
  }

  &__validation {
    padding-top: space(sm);
    color: palette(ui, error);
    font-size: 0.8rem;
  }

  &__submit {
    margin: space(xl) 0;
  }
}

.c-select {
  display: block;
  position: relative;
  padding: 0 1rem;
  border: 0 !important;
  border-radius: 4px;
  outline: 0;
  background-color: palette(gray, x-light);
  color: $gray;
  font-size: modular-scale(0);
  box-shadow: none;
  appearance: none;
  @include size(100%, 2.5rem);

  &::after {
    width: 20px;
    height: 5px;
    background-color: $black;
    @include pseudo;
  }
}
