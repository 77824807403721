.c-card {
  position: relative;

  .o-list--separated & {
    margin-right: space(lg);
    margin-left: space(lg);
  }

  > a {
    color: $black;
    text-decoration: none;
  }

  &__category {
    margin-bottom: space(sm);
    font-size: modular-scale(-1);
    font-weight: 700;
    color: $gray;
  }

  &__image {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;

    > a {
      display: block;
    }

    .media {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px 5px 3px;
      border-top-left-radius: 5px;
      background-color: rgba($black, 0.8);
      color: $white;
      opacity: 0.9;
      // @include size(30px);
      @include position(absolute, null 0 0 null);

      .duration {
        margin-top: 3px;
        font-size: 8px;
      }
    }

    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }

  &__title {
    margin: 0 space(xl) space(sm) 0;
    font-size: modular-scale(0);
    font-weight: 700;
    line-height: 1.3;

    .c-card--audio &,
    .c-card--news-horizontal &,
    .c-card--gallery-horizontal &,
    .c-card--show & {
      margin-right: 0;
    }

    .o-list--podcasts & {
      margin-bottom: 0;
    }
  }

  &__unavailable {
    margin-bottom: space(sm);

    span {
      display: inline-flex;
      align-items: center;
      padding: space(sm);
      border-radius: 4px;
      background-color: palette(grey, xx-light);
      color: palette(grey, dark);
      font-size: modular-scale(-3);
      letter-spacing: 1px;
      text-transform: uppercase;

      svg {
        margin-right: space(sm);
      }
    }
  }

  &__meta {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__description {
    margin-top: space(md);
  }

  &__date,
  &__episode {
    color: $gray;
    font-size: modular-scale(-2);
  }

  &__date {
    margin-top: 8px;
  }

  &__date,
  &__category {

    .o-list--podcasts & {
      display: none;
    }
  }

  &__episode {
    margin-bottom: space(sm);
  }

  &__cta {
    display: flex;
    margin-top: space(md);

    .c-btn {
      margin-right: space(md);
    }
  }

  // Common styles

  &--news,
  &--gallery-vertical {
    display: flex;
    align-items: flex-start;
  }

  &--audio,
  &--user,
  &--show,
  &--song,
  &--gallery-horizontal,
  &--contest {
    display: flex;
    align-items: flex-start;
  }

  &--audio,
  &--show,
  &--gallery-horizontal,
  &--user,
  &--contest,
  &--countdown,
  &--news-horizontal {
    margin-top: space(md);
    margin-bottom: space(md);

    > a {

      .o-list--podcasts & {
        align-items: center;
      }
    }
  }

  &--audio,
  &--show,
  &--gallery-horizontal,
  &--user,
  &--countdown,
  &--news-horizontal {

    > a {
      display: flex;
      position: relative;
      align-items: flex-start;
      width: 100%;
      padding-right: 1.5rem;

      &[href]:not(:empty) {
        @include pointer;
      }
    }
  }

  &--audio,
  &--audio-widget,
  &--news-horizontal {

    .l-story & {
      margin-bottom: space(xl);
      padding: space(lg);
      border-radius: 8px;
      box-shadow: 0px 4px 8px rgba($black, 0.1);
    }
  }

  &--show,
  &--gallery-horizontal,
  &--user,
  &--countdown,
  &--news-horizontal {

    .c-card__image {
      margin-right: space(base);
    }
  }

  &--show,
  &--user,
  &--contest,
  &--countdown {
    align-items: center;

    > a {
      align-items: center;
    }
  }

  &--news-horizontal,
  &--gallery-horizontal {

    .c-card__image {
      flex: 0 0 $card-image-height-sm;
      max-width: $card-image-height-sm;
      height: $card-image-height-sm;
      overflow: hidden;

      @media (min-width: $bp-xs) {
        flex-basis: $card-image-width-sm;
        max-width: none;
      }

      img {

        @media (max-width: ($bp-xs - 1px)) {
          width: auto;
          max-width: none;
          height: $card-image-height-sm;
          transform: translateX(-27px); // fixed value as long as $card-image-height-sm = 75px
          @include position(absolute, 0 null null 0);
        }
      }
    }

    img {
      display: block;
    }
  }

  &--news-vertical,
  &--gallery-vertical,
  &--podcast {

    .c-card__image {
      margin-bottom: space(base);
    }

    .c-card__visual {
      position: relative;

      .c-btn {
        @include position(absolute, null space(sm) space(sm) null);
      }
    }
  }

  &--show,
  &--user {

    img {
      border-radius: 5px;
    }
  }

  // Photo

  &--photo {
    background-color: $black;
    color: $white;

    label {
      position: relative;
      z-index: 999;
      margin: 0;
      border-radius: 10px;

      &::before,
      &::after {
        content: '';
        display: block;
        background-color: $white;
        @include position(absolute, null 1rem 10px null);
        @include size(8px, 1px);
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
        transform-origin: 13px center;
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
        transform-origin: -5px 0;
      }
    }

    input {
      display: none;
    }

    input:not(:checked) ~ label {
      @include position(absolute, null 0 0 0);
      @include size(100%, 3.5rem);
    }

    input:checked ~ label {
      background-color: transparent;
      @include position(absolute, null 0.8rem 0.6rem null);
      @include size(22px);

      &::before,
      &::after {
        top: 50%;
        left: 50%;
        transform-origin: center center;
        @include size(12px, 2px);
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    input:checked ~ .c-card__figcaption {
      z-index: 100;
      top: 0;
      max-height: 100%;
      padding-top: space(xl);
      padding-bottom: space(lg);
      overflow-y: auto;
      background-color: rgba($black, 0.85);
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $white;
      }
    }

    input:checked ~ .c-card__figcaption .c-card__source {
      display: block !important;
      margin-top: 0.5em;
    }

    input:not(:checked) ~ .c-card__figcaption .c-card__legend {
      display: block;
      max-width: 100%;
      max-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: normal;
      white-space: nowrap;
    }

    .c-card__figcaption {
      z-index: 1;
      padding: 0.75rem 2.5rem 10px space(lg);
      background-color: rgba($black, 0.5);
      @include position(absolute, null 0 0 0);

      > * {
        margin: 0;
      }
    }

    .c-card__caption {
      font-weight: 700;
      line-height: 1.25;
    }

    .c-card__source {
      opacity: 0.75;
      font-size: 0.7em;
    }

    .c-card__legend {
      margin-top: space(sm);
      font-size: 14px;
      line-height: 20px;
    }
  }

  // Audio

  &--audio {

    .c-card__data {
      width: $card-image-height-sm;
      margin-right: space(base);
      text-align: center;

      @media (min-width: $bp-xs) {
        flex: 0 0 $card-image-width-sm;
      }
    }

    .c-card__visual {
      position: relative;
      width: $card-image-height-sm;
      height: $card-image-height-sm;
      overflow: hidden;

      @media (min-width: $bp-xs) {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }

    .c-card__image {
      @include position(absolute, 0 null null 0);
      @include size(100%);

      img {
        width: auto;
        max-width: none;
        height: $card-image-height-sm;
        margin-left: -28px;

        @media (min-width: $bp-xs) {
          margin-left: 0;
        }
      }
    }

    .o-list & {

      .c-card__share {

        .c-btn {

          @media (min-width: 550px) {
            transform: translateY(-50%);
            @include position(absolute, 50% 0 null null);
          }
        }
      }

      .c-card__meta {

        @media (min-width: 550px) {
          margin-right: 6rem;
        }
      }
    }

    .c-card__share {
      margin-top: 0.5rem;
    }

    .c-card__description {
      font-size: modular-scale(-1);

      &[aria-expanded='true'] {

        p:first-child {
          line-clamp: initial;
          -webkit-line-clamp: initial;
        }
      }

      p {
        display: none;

        &:first-child {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          line-clamp: 3;
          -webkit-line-clamp: 3;
        }
      }
    }

    &.c-card--1x1 {

      .c-card__data {

        @media (min-width: $bp-xs) {
          flex: 0 0 $card-image-width-sm;
        }
      }

      .c-card__visual {

        @media (min-width: $bp-xs) {
          @include size($card-image-width-sm);
        }
      }

      .c-card__image {

        img {
          margin-left: 0;
          @include size(100%);
        }
      }
    }
  }

  &--audio-widget {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    .c-card__button {
      display: block;
      width: 100%;
      text-align: center;

      .c-btn {
        display: block;
        width: 100%;
      }
    }

    .c-card__meta {
      display: flex;
      align-items: center;
      // flex: 1 1 auto;
    }

    .c-card__title {
      flex: 1 1 auto;
      margin-right: 0;
    }
  }

  // Show

  &--show {

    .c-card__time {
      color: $gray;
      font-size: modular-scale(-1);
      font-weight: 700;
    }

    .c-card__image {
      position: relative;
      flex-basis: 75px;
    }

    .c-card__meta {
      flex-basis: 80%;

      .host {
        color: $gray;
        font-size: modular-scale(-1);
        line-height: 1.2;
      }
    }

    .audio-icon {
      display: flex;
      z-index: 1;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: $white;
      @include position(absolute, null -3px 0 null);
    }
  }

  // Gallery

  &--gallery-horizontal {

    .c-card__image {
      flex-basis: 125px;
    }
  }

  &--gallery-vertical {
    width: 185px;
    background-color: $white;

    > a {
      flex-direction: column;
    }
  }

  // User

  &--user {

    .c-card__image {
      flex-basis: 70px;
    }
  }

  // Podcast

  // &--podcast {}

  // Contest

  &--contest {

    > a {
      @media (min-width: $bp-xs) {
        display: flex;

        &[href]:not(:empty) {
          @include pointer;
        }
      }
    }

    .c-card__image {

      @media (min-width: $bp-xs) {
        flex-basis: 125px;
        margin-right: space(base);
      }
    }

    .c-card__meta {

      @media (max-width: ($bp-xs - 1px)) {
        margin-top: space(base);
      }
    }

    .external {
      color: palette(gray);
      font-size: modular-scale(-2);
    }
  }

  // News

  &--news-vertical {
    width: 185px;
    background-color: $white;

    > a {
      display: block;
    }
  }

  &--news-horizontal {

    .c-card__author,
    .c-card__show {
      font-size: modular-scale(-2);
    }

    .c-card__description {
      font-size: modular-scale(-1);

      &.line-clamp {
        max-height: 4.5em;
        overflow: hidden;
      }
    }
  }

  // Ad

  &--ad {
    padding: 2rem 0;
    background-color: palette(gray, xx-light);
    text-align: center;

    .l-story &,
    &.c-card--ad-top {
      background-color: transparent;
    }

    .c-carousel & {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 400px;
    }

    &.c-card--ad-top {
      margin-top: space(lg) * -1;
    }

    .o-list--separated & {
      margin-right: 0;
      margin-left: space(base) * -1;
    }

    > *,
    > * > * {
      margin: 0 auto;
    }

    // sass-lint:disable no-qualifying-elements
    div[data-m32-dynamic-ad],
    div[data-m32-ad] {
      position: relative;

      &::before {
        content: 'Publicité';
        width: 100%;
        height: 1.5rem;
        color: $gray;
        font-size: 11px;
        line-height: 1.5rem;
        text-align: center;
        text-transform: uppercase;
        @include position(absolute, -1.5rem null null 0);

        .station-thebeat & {
          content: 'Advertisement';
        }
      }
    }

    .c-btn {
      margin-top: space(xl);
    }
  }

  // Song

  &--song {
    position: relative;
    align-items: center;

    .c-card__rank {
      flex: 0 0 35px;
      margin-right: space(base);
      border-radius: 35px;
      background-color: $gray;
      color: $white;
      font-size: 1.2em;
      font-weight: 700;
      line-height: 35px;
      text-align: center;
      @include size(35px);
    }

    .c-card__image {
      flex: 0 0 45px;
      margin-right: space(base);
    }

    .c-card__title {
      margin: 0;
      font-size: modular-scale(-1);
    }

    .artist {
      color: palette(gray);
      font-size: 0.8em;
    }

    .c-card__controls {
      display: flex;
      flex: 0 0 55px;
      justify-content: space-between;
    }
  }

  // Countdown

  &--countdown {
    background-color: $white;

    > a::before,
    > a::after {
      right: 10px !important;
    }

    .c-card__image {
      flex: 0 0 80px;
      @include size(80px);

      .position {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $gray;
        color: $white;
        font-weight: 700;
        @include position(absolute, null 5px 5px null);
        @include size(2rem);
      }
    }

    .c-card__meta {
      flex: 1 1 calc(100% - 80px);
    }

    .artist {
      color: $gray;
      font-size: modular-scale(-2);
    }
  }

  // Player notification

  &--player-notification {
    display: flex;
    z-index: 9999;
    justify-content: space-between;
    padding: 1em;
    transform: translateY(100%);
    transition: transform .3s ease-in;
    border-radius: 5px 5px 0 0;
    background-color: palette(black, light);
    color: $white;
    font-family: font-family(text);
    font-size: modular-scale(-1);
    line-height: 1.1;
    @include position(fixed, null 0 0 0);

    .is-triggered & {
      transform: translateY(0);
    }
  }

  // Loading

  &--loading {
    margin-top: space(md);
    padding: 1em 2em;
    color: palette(gray, dark);
    text-align: center;

    svg {
      width: 2.5em;
      height: 2.5em;
    }

    p {
      margin: 1em 0 0;
      color: palette(gray, light);
      font-size: modular-scale(-3);
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }

  // Horizontal Block

  &--horizontal-center {
    margin: space(md) 0;
    text-align: center;
  }
}

@keyframes popup {

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}
