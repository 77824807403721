.c-carousel {

  &--lineup {
    margin-bottom: space(xl);

    .swiper-slide {
      width: 10rem;
    }

    &.c-carousel--content {

      .swiper-slide {
        width: 185px;
      }
    }
  }

  .l-story--article & {
    margin-bottom: space(xl);
  }

  .l-story--photo & {
    background-color: $black;
    color: $white;
  }

  &__swiper {
    position: relative;
    margin: 0 auto;
    overflow: hidden;

    .swiper-wrapper {
      margin-bottom: -1px;
    }
  }

  &__prev,
  &__next {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    transition: opacity 0.3s ease-in;
    background-color: rgba($black, 0.7);
    color: $white;
    outline: none;
    z-index: 99;
    @include size(3rem);

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  &__prev {
    @include position(absolute, 50% null null 0);
  }

  &__next {
    @include position(absolute, 50% 0 null null);
  }

  &__meta {
    max-width: 55rem;
    margin: 0 auto;
    padding: space(lg);
  }

  &__caption {
    margin: space(sm) 0 0;
  }

  &__legend {
    margin: space(sm) 0 0;
  }

  &__pagination,
  .swiper-pagination-custom {
    width: auto;
    padding: 0.5rem 1rem;
    color: $white;
    font-size: modular-scale(-2);
    text-shadow: 0 0 4px rgba($black, 0.6);
    z-index: 99;
    @include position(absolute, 0 0 auto auto);
  }

  figure,
  img {
    width: auto;
    max-width: 100%;
    max-height: 90vh;
    margin: 0 auto;
  }
}

.carousel:focus {
  outline: none;
}
