.c-modal {

  &__header {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 0.75rem;
    box-shadow: 0 4px 4px rgba($black, 0.25);
    z-index: 2;

    h2 {
      margin: 0;
      font-size: 1rem;
    }

    .close {
      font-size: 1.25rem;
    }
  }

  &__content {
    display: flex;
    position: relative;
    background-color: palette(gray, xx-light);
    z-index: 1;

    img {
      flex: 0 0 106px;
      height: 60px;
      margin-right: 10px;
      border-radius: 4px;
    }

    &.c-modal__content-1x1 {

      img {
        flex-basis: 60px;
      }
    }
  }

  &__info {
    flex: 1 1 calc(100% - 70px);

    .title {
      margin: 0.25em 0 0.5em;
      font-size: modular-scale(-1);
    }

    .info {
      margin: 0;
      color: $gray;
      font-size: modular-scale(-2);
      font-weight: 700;
    }
  }

  &__btn-list {
    @include list-unstyled;

    li + li {
      margin-top: 0.5rem;
    }

    .c-btn {
      font-size: modular-scale(-1);

      span {
        margin: 0 auto;
      }
    }
  }
}

// Bootstrap Overrides

.modal-dialog {
  padding: 0;
  outline: none;
  border: 0;
  background-color: transparent;
  box-shadow: none;
}

.modal-content {
  border: none;
}
