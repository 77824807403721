// Descriptive Base Colors

$white: #fff;
$black: #0b0b0b;
$gray: #797e83;

// Palettes

$color-palettes: (
  ui: (
    success: #5ece7f,
    error: #e67478,
    warning: #ff784f,
    info: #52bab3
  ),
  social: (
    facebook: #3b5998,
    twitter: #1da1f2,
    youtube: #f00
  ),
  gray: (
    xx-light: lighten($gray, 43%),
    x-light: lighten($gray, 35%),
    light: lighten($gray, 12%),
    base: $gray,
    dark: darken($gray, 8%),
    x-dark: darken($gray, 16%)
  ),
  black: (
    light: lighten($black, 10%),
    base: $black,
    dark: darken($black, 10%)
  )
);

// Brands

$brand-palette: (
  beat: (
    base: #8a2e83,
    accent: #de4196
  ),
  cime: (
    base: #f15a22,
    accent: #ff3700
  ),
  ckoi: (
    base: #d8001c,
    accent: #e52c34
  ),
  fm93: (
    base: #00b0c2,
    accent: #2e3192
  ),
  rythme: (
    base: #eb0060,
    accent: #960051
  ),
  talk: (
    base: #21409a,
    accent: #1199d5
  ),
  kyk: (
    base: #000,
    accent: #ffb900
  ),
  planete: (
    base: #4d5cb9,
    accent: #37b4e5
  ),
  radiocirculation: (
    base: #0067b1,
    accent: #ffa400
  ),
  cmi: (
    base: #004282,
    accent: #00a3da
  )
);
