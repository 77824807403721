.c-card-placeholder {
  margin-top: space(md);
  margin-bottom: space(md);

  &--horizontal {
    @include placeholder(
      $img-width: 133px,
      $img-height: 75px,
      $line1-width: 45px,
      $line1-height: 15px,
      $line2-width: 250px,
      $line2-height: 15px,
      $line3-width: 175px,
      $line3-height: 15px
    );
  }

  &--grid {
    margin-bottom: space(xl);

    .c-card-placeholder__animation {
      height: 0;
      padding-top: calc(100% + 40px);
    }

    .image-bottom,
    .firstline-bottom,
    .firstline-right,
    .secondline-right {
      width: 100%;
      height: 10px;
    }

    .firstline-right,
    .secondline-right {
      right: 0;
    }

    .image-bottom {
      bottom: 30px;
    }

    .firstline-bottom {
      bottom: 10px;
    }

    .firstline-right {
      bottom: 20px;
      width: 10%;
    }

    .secondline-right {
      bottom: 0;
      width: 20%;
    }
  }

  &--no-category {
    @include placeholder(
      $img-width: 133px,
      $img-height: 75px,
      $line1-width: 250px,
      $line1-height: 15px,
      $line2-width: 170px,
      $line2-height: 15px,
      $top-space: 15px
    );

    .secondline-space {
      top: 50px;
      height: 30px;
    }
  }

  &--single-line {
    height: 75px;

    .image-right {
      top: 0;
      left: 133px;
      width: 10px;
      height: 75px;

      @media (max-width: $bp-xs - 1px) {
        left: 75px;
      }
    }

    .firstline-top,
    .firstline-space {
      left: 143px;
      width: calc(100% - 143px);

      @media (max-width: $bp-xs - 1px) {
        left: 85px;
        width: calc(100% - 85px);
      }
    }

    .firstline-top {
      top: 0;
      height: 25px;
    }

    .firstline-space {
      top: 45px;
      height: 30px;
    }

    .firstline-right {
      top: 25px;
      right: 0;
      width: calc(100% - 425px);
      height: 25px;
    }
  }

  &--square {
    @include placeholder(
      $img-width: 75px,
      $img-height: 75px,
      $line1-width: 250px,
      $line1-height: 15px,
      $line2-width: 200px,
      $line2-height: 15px
    );
  }

  &--countdown {
    @include placeholder(
      $img-width: 80px,
      $img-height: 80px,
      $line1-width: 175px,
      $line1-height: 20px,
      $line2-width: 250px,
      $line2-height: 15px,
      $top-space: 20px
    );
  }

  &--song {
    @include placeholder(
      $img-width: 45px,
      $img-height: 45px,
      $line1-width: 175px,
      $line1-height: 15px,
      $line2-width: 80px,
      $line2-height: 15px
    );
  }

  &__animation {
    position: relative;
    height: 100%;
    background: linear-gradient(
      to right,
      palette(gray, xx-light),
      palette(gray, x-light) 18%,
      palette(gray, xx-light) 33%
    );
    background-size: 900px 104px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

    .c-card-placeholder--horizontal &,
    .c-card-placeholder--no-category &,
    .c-card-placeholder--single-line &,
    .c-card-placeholder--square & {
      margin: 0 20px;
    }
  }

  .animation-mask {
    position: absolute;
    background-color: $white;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
