// ==========================================================================
// Base Styles
// ==========================================================================

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: font-family(text);
}

a {
  text-decoration: none;
}

figure,
picture {
  margin: auto;
  max-width: 100%;

  > img {
    display: block;
    width: 100%;
    max-width: inherit;
  }
}

picture {
  display: block;
}

ul,
ol,
dl {
  margin-bottom: 0;
}

#{$all-buttons} {
  padding: 0;
  border: 0 none;
  background: none;

  &:focus {
    outline: none;
  }
}

// Adds default UL and LI styles back ONLY for articles

article {
  ul,
  ol {
    padding-left: 2rem;
  }

  li + li {
    margin-top: vr(0.25);
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}
