h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: 0.4em;
  line-height: 1.2;

  .o-block > & {
    margin-bottom: space(lg);
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    font-size: modular-scale(5 - $i);
  }
}

// Sponsor

h2 {

  .sponsor {
    display: flex;
    align-items: center;
    font-size: 0.5em;

    span {
      display: inline-block;
      margin-right: space(sm);
      white-space: nowrap;
    }

    img {
      min-width: 50px;
      max-width: 100px;
      max-height: 25px;
      height: auto;
      object-fit: contain;
    }
  }
}
