.o-list {
  margin: 0;
  @include list-unstyled;

  li {
    margin-bottom: space(md);
  }

  &--separated {

    li {
      margin-bottom: 0;
      border-bottom: 1px solid palette(gray, x-light);

      .l-container & {
        margin-right: space(lg) * -1;
        margin-left: space(lg) * -1;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  // stylelint-disable property-no-vendor-prefix
  &--horizontal {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    > * {
      margin-right: space(base);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.o-btn-list {
  display: flex;
  flex-wrap: wrap;
}
