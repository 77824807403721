@use "sass:math";

.ad {

  &--video {
    display: none;
    z-index: 10000;
    width: calc(100% - 30px);
    max-width: 640px;
    height: 0;
    margin: 0;
    padding-top: percentage(math.div(9, 16));
    transform: translate(-50%, -50%); 
    @include position(fixed, 50% null null 50%);

    @media (min-width: 640px) {
      padding: 0;
      @include size(640px, 360px);
    }
  }

  &__container {

    .ad--video & {
      @include position(absolute, 0);

      &::before {
        content: 'Publicité';
        z-index: 2;
        display: block;
        background-color: $black;
        color: $white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        @include position(absolute, -20px 0 null 0);
      }

      // DFP Video ads

      div,
      video {
        top: 0;
        left: 0;
      }

      > div,
      iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  &__message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: palette(grey, xx-light);
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    @include position(absolute, -60px 0 null 0);
  }

  &-overlay {

    .preroll-is-visible & {
      z-index: 99;
      background-color: rgba($black, 0.8);
      @include position(fixed, 0);
    }
  }
}
