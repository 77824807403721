.l-story {

  &--photo {

    .l-story__byline {
      margin-bottom: 0;
    }
  }

  .source {
    padding: 0 space(base);
    color: $gray;
    font-size: modular-scale(-3);
  }

  & + .l-container {
    border-top: 1px solid palette(gray, x-light);
  }

  &__header,
  &__body {
    margin: 0 auto;
  }

  &__byline {
    max-width: 55rem;
    margin-bottom: space(lg);

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .author,
    .date {
      .l-story--podcast & {
        display: none;
      }
    }

    .author {
      font-size: modular-scale(-1);
    }

    .date {
      color: palette(gray, dark);
      font-size: modular-scale(-2);
      @include ellipsis;
    }
  }

  &__image {
    position: relative;
    margin-bottom: space(xl);

    figure {
      max-width: 55rem;
    }

    figcaption {
      margin-top: space(sm);
      color: palette(gray, dark);
      font-size: modular-scale(-2);
    }

    .source {
      margin: 0;
      padding: 0;
      line-height: 1.25rem;
      @include position(absolute, null 0 -1.25rem null);
    }

    .caption {
      padding: space(base);
      background-color: rgba($black, 0.5);
      color: $white;
      font-size: modular-scale(-2);
      line-height: 1.25;
      @include position(absolute, null 0 0 0);
    }
  }

  &__share {
    display: flex;
    justify-content: center;
    margin-bottom: space(xl);

    h4 {
      display: inline-block;
      margin-right: 0.5em;
      color: $gray;
      font-size: 0.75em;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  &__body {
    max-width: 35rem;
    white-space: pre-wrap;
    line-height: 1.25;

    img {
      max-width: 100%;

      & + .source {
        padding-left: 0;
      }
    }

    blockquote {
      margin: space(xl) 0;
      padding: space(sm) space(lg);
      border-left: 2px solid palette(gray, x-light);
      color: palette(gray);

      p {
        margin-bottom: 0 !important;
        font-family: font-family(quote);
        font-size: modular-scale(1);
        font-style: italic;
      }

      footer {
        margin-top: space(md);
        color: palette(gray);
        text-align: right;

        &::before {
          margin-right: 0.5em;
          content: '\2014';
        }
      }
    }

    card-audio + quote-widget > blockquote {
      padding-top: 0;
      border-top: none;
    }

    card-audio + card-audio .c-card--audio {
      border-top: none;
    }

    .l-story__image + quote-widget {
      display: block;
      margin-top: 3rem;
    }

    iframe,
    twitter-widget {
      display: block;
      max-width: 100%;
      margin: space(lg) auto !important;
    }
  }

  &__text,
  &__gallery,
  &__embed {
    margin-bottom: space(xl);
  }

  &__text {
    white-space: normal;

    h2 {
      margin-top: space(lg);
      margin-bottom: space(lg);
    }

    h3 {
      margin-top: space(lg);
      margin-bottom: space(md);
    }

    ul,
    ol,
    p,
    hr {
      margin-bottom: space(md);
    }

    p {
      line-height: 1.5;
    }

    ul,
    ol {

      > li {
        margin-bottom: space(sm);
      }
    }

    hr {
      margin: 0 0 space(md);
      border-top: 1px solid palette(gray, x-light);
    }
  }

  &__audio {
    margin-top: space(xl);
  }
}
