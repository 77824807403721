.c-btn {
  display: inline-flex;
  align-items: baseline;
  position: relative;
  padding: space(base) space(md);
  border-radius: 5px;
  outline: none;
  color: $white;
  font-size: modular-scale(0);
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .o-list + &,
  audio-widget-list + & {
    margin-top: space(xl);
  }

  .o-btn-list & {
    margin: space(sm) space(md) 0 0;
  }

  &:not(.c-btn--icon):not(.c-btn--rounded) .ng-fa-icon {
    margin-right: 0.5em;
  }

  &--primary,
  &--secondary {

    .duration {
      margin-left: 0.5rem;
      opacity: 0.75;
      font-size: modular-scale(-1);
      font-weight: 400;
    }
  }

  &--secondary {

    .label,
    .duration {

      @media (max-width: ($bp-xs - 1px)) {
        display: none;
      }
    }
  }

  &--block {
    width: 100%;
    text-align: center;

    > svg {
      transform: translateY(-50%);
      @include size(24px);
      @include position(absolute, 50% 10px null null);
    }

    cmi-content-list + & {
      margin-top: space(lg);
    }
  }

  &--rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background-color: palette(grey, xx-light);
    color: $black;
    @include size(2rem);
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 32px;
    @include size(32px);
  }

  &--share {
    padding: 0.2rem 0.5rem;
    border: 1px solid palette(gray, x-light);
    border-radius: 5px;
    color: $gray;
    font-size: 0.8rem;
    font-weight: 700;

    @media (max-width: ($bp-xs - 1px)) {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }

    .ng-fa-icon {

      @media (min-width: $bp-xs) {
        margin-left: 0.5em;
      }
    }

    .label {

      @media (max-width: ($bp-xs - 1px)) {
        display: none;
      }
    }
  }

  &--outline {
    border: 1px solid palette(gray, x-light);
    background-color: transparent;
    color: palette(gray, dark);

    &:hover {
      border-color: palette(gray, x-dark);
      color: palette(gray, x-dark);
    }
  }

  &--inverted {
    border-color: $white;
    color: $white;
  }

  &--xlarge {
    display: inline-block;
    padding: 1rem;
    border-radius: 4px;
    background-color: $white;
    color: $black;
    font-size: 1.4rem;
    line-height: 1;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    box-shadow: 0 5px 0 0 rgba($black, 0.25);
    cursor: pointer;

    &:hover {
      color: $gray;
    }
  }

  $networks: facebook, twitter;

  @each $network in $networks {
    &--#{$network} {
      color: $white;
      background-color: palette(social, $network) !important;

      &:hover {
        background-color: lighten(palette(social, $network), 5%) !important;
      }
    }
  }
}
