.nav-tabs--schedule {
  display: flex;
  margin: 0 0 1rem 0;
  padding: 0;
  border: 1px solid palette(gray, x-light);
  border-radius: 5px;
  list-style: none;

  .nav-item {
    border-right: 1px solid palette(gray, x-light);
    cursor: pointer;

    &:first-child {

      a {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    &:last-child {
      border-right: 0;

      a {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .nav-link {
    display: block;
    padding: 5px 0;
    color: $gray;
    text-align: center;

    span {
      font-size: modular-scale(-1);
      font-weight: 700;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;

      .station--thebeat & {
        text-transform: capitalize;
      }
    }

    &.active {
      color: $white;
    }
  }
}


// .nav-tabs {
//   display: flex;
//   height: 34px;
//   width: 100%;
//   border: 1px solid #dee2e6;
//   border-radius: 5px;
// }
// .nav-tabs .nav-link {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 0;
//   border-radius: 0;
//   border-right: 1px solid #dee2e6;
//   font-weight: bold;
//   height: 33px;
// }
// .nav-tabs .nav-link.active {
//   color: #fff;
//   background-color: #75d1f6;
//   border: 0;
//   border-radius: 0;
//   height: 33px;
// }
// .nav-tabs .li-0 .nav-link.active {
//   border-radius: 5px 0 0 5px;
// }
// .nav-tabs .li-6 .nav-link.active {
//   border-radius: 0 5px 5px 0;
// }
