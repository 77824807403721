#didomi-host {

  // General

  .didomi-popup-backdrop {
    background-color: rgba($black, .4) !important;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
  }

  .didomi-exterior-border {
    border-color: transparent;
    box-shadow: 0px 8px 24px rgba($black, 0.1);
  }

  .didomi-popup-container.didomi-popup__dialog.didomi-popup-notice {
    padding: 32px 48px !important;
  }
  
  .didomi-mobile .didomi-popup-container.didomi-popup__dialog.didomi-popup-notice {
    padding: 28px !important;
  }

  .didomi-popup-notice.didomi-popup-notice-with-data-processing p {

    span {
      font-size: inherit !important;
      font-weight: 400 !important;

      &.didomi-notice-data-processing-title {
        font-weight: 700 !important;
        margin-bottom: 12px;
      }
    }
  }

  .didomi-notice-data-processing-container p span.didomi-notice-data-processing-title {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 1.1 !important;
  }

  .didomi-notice-data-processing-container p span.didomi-notice-data-processing-list {
    display: block;
    text-align: left !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .didomi-popup-footer .didomi-popup-actions div.didomi-buttons-all {
    margin-right: 12px;
    margin-bottom: 24px;
  }

  // Mobile Layout

  .didomi-mobile {

    #didomi-popup .didomi-popup-notice p {
      text-align: left !important;
    }

    #buttons {
      position: sticky;
      bottom: 6px;
      padding-bottom: 80px;
      margin-bottom: -24px;
      background-color: $white;
  
      &::before {
        content: '';
        display: block;
        background: linear-gradient(0deg, rgba($white, 100%), rgba($white, 0%));
        @include size(100%, 24px);
        @include position(absolute, -24px null null null);
      }
    }
  }

  // Borders

  .didomi-popup-container {
    border-color: transparent !important;
    border-radius: 8px !important;
  }

  .didomi-exterior-border {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  // Logo

  .didomi-popup-container .didomi-popup-notice-logo-container {
    text-align: left !important;
  }

  .didomi-popup-notice .didomi-popup-notice-logo {
    width: 135px !important;
    margin-bottom: 32px;
  }

  // Buttons

  .didomi-buttons .didomi-button.didomi-button-highlight,
  .didomi-buttons .didomi-button.didomi-button-standard {
    min-width: 0 !important;
    height: auto !important;
    padding: 12px 24px !important;
    border-radius: 24px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    transition: color .3s ease-in,background-color .3s ease-in,border-color .3s ease-in !important;

    span {
      white-space: nowrap;
    }
  }

  #didomi-notice-disagree-button,
  #didomi-notice-agree-button,
  #didomi-notice-learn-more-button {
    padding: 12px 24px !important;
  }

  .didomi-buttons .didomi-button.didomi-button-standard {
    border: 1px solid palette(grey, xx-light) !important;
    background-color: $white !important;
    color: $black !important;
  }

  .didomi-buttons .didomi-button.didomi-button-standard:hover {
    border-color: $black !important;
  }

  .didomi-buttons .didomi-button.didomi-button-highlight {
    background-color: brand(cmi) !important;
    color: $white !important;
  }

  .didomi-buttons .didomi-button.didomi-button-highlight:hover {
    background-color: #0057B8 !important;
  }

  .didomi-components-radio__option {
    border-radius: 4px !important;
    box-shadow: none !important;

    &.didomi-components-radio__option--agree {
      background-color: ui(success) !important;
      border-color: transparent !important;
    }
    
    &.didomi-components-radio__option--disagree {
      background-color: ui(danger) !important;
      border-color: transparent !important;
    }
  }

  // Links

  [dataTooltip] {
    border-bottom-color: brand(cmi, accent) !important;

    &:focus::after {
      padding: 12px;
      border: none;
      border-radius: 4px;
    }
  }

  // Notive Window

  .didomi-popup-notice {

    .didomi-popup-notice-text {
      padding-bottom: 24px;

      p {
        font-size: 14px !important;
        line-height: 18px !important;
      }

      > p  {
        
        > strong:first-child {
          display: block;
          font-size: 18px;
          margin-bottom: 12px;
        }

        > br:nth-child(2) {
          display: none;
        }
      }
    }

    .didomi-popup-notice-text-container .didomi-popup-notice-data-processing-list {
      display: none;
    }

    .didomi-popup-notice-buttons {
      justify-content: flex-end !important;
    }
  }

  // Preferences Window

  .didomi-consent-popup-preferences {
    padding-bottom: 80px;

    .didomi-consent-popup-header {
      padding: 12px 20px 0 !important;

      + .didomi-consent-popup-body {
        padding-top: 12px !important;
      }
    }

    .didomi-consent-popup-preferences-purposes-features {
      display: none;
    }

    .didomi-consent-popup-body__subtext {
      margin-bottom: 0 !important;
      font-size: 12px;
    }

    .parent-feature-and-special-purpose {
      display: block;
      margin-top: 8px;
    }

    .didomi-consent-popup-body {
      padding-top: 8px;
      color: $black;
      font-size: 13px !important;
      line-height: 17px !important;

      .didomi-consent-popup-body__explanation {
        margin-bottom: 24px;
        padding-bottom: 18px;
        border-bottom: 1px solid palette(grey, xx-light);

        a {
          font-weight: 500;
        }
      }
    }

    .didomi-components-accordion {
      margin-top: 12px;
    }

    .didomi-popup-actions {
      margin-right: 16px;
      margin-bottom: 24px;
    }
  }
}
