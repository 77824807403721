@use "sass:math";

// Breakpoints
$bp-xs: 400px;
$bp-md: 620px;

// Small Horizontal Card
$card-image-height-sm: 75px;
$card-image-width-sm: math.div(($card-image-height-sm * 16), 9);

// Cards Horizontal Image width (large)
$card-image-height-lg: 100px;
