// ==========================================================================
// Mixins
// ==========================================================================

// Pseudos elements

@mixin pseudo($display: block, $content: '', $pos: absolute) {
  display: $display;
  position: $pos;
  content: $content;
}

// Ratio image container

@mixin responsive-ratio($ratio-x, $ratio-y, $pseudo: false) {
  $padding: percentage($ratio-y/$ratio-x);

  @if $pseudo {
    &::before {
      width: 100%;
      padding-top: $padding;
      @include pseudo($pos: relative);
    }
  } @else {
    height: 0;
    padding-top: $padding;
    overflow: hidden;
  }

  > * {
    @include position(absolute, 0 null null 0);
  }
}

// Put a right pointer on the right side of the element

@mixin pointer {
  &::before,
  &::after {
    width: 15px;
    height: 3px;
    @include pseudo;
    @include position(absolute, 50% 0 null null);
  }

  &::before {
    transform: rotate(45deg) translateX(3px);
    transform-origin: right top;
  }

  &::after {
    transform: rotate(-45deg);
    transform-origin: right top;
  }
}

// Placeholders

@mixin placeholder(
  $img-width,
  $img-height,
  $line1-width,
  $line1-height,
  $line2-width,
  $line2-height,
  $line3-width: '',
  $line3-height: '',
  $img-margin: 10px,
  $top-space: 10px,
  $line-space: 5px
) {
  height: $img-height;

  .image-right {
    top: 0;
    left: $img-width;
    width: $img-margin;
    height: $img-height;
  }

  .firstline-top {
    height: $top-space;
  }

  .firstline-space,
  .secondline-space {
    height: $line-space;
  }

  .firstline-top,
  .firstline-space,
  .secondline-space,
  .thirdline-bottom {
    left: $img-width + $img-margin;
    width: calc(100% - #{$img-width + $img-margin});
  }

  .firstline-top {
    top: 0;
  }

  .firstline-right {
    top: $top-space;
    right: 0;
    width: calc(100% - #{$img-width + $img-margin + $line1-width});
    height: $line1-height;
  }

  .firstline-space {
    top: $line1-height + $top-space;
  }

  .secondline-right {
    top: $line1-height + $top-space + $line-space;
    right: 0;
    width: calc(100% - #{$img-width + $img-margin + $line2-width});
    height: $line2-height;
  }

  @if $line3-width != '' {
    .secondline-space {
      top: $line1-height + $line2-height + $top-space + $line-space;
    }

    .thirdline-right {
      top: $line1-height + $line2-height + $top-space + ($line-space * 2);
      right: 0;
      width: calc(100% - #{$img-width + $img-margin + $line3-width});
      height: $line3-height;
    }

    .thirdline-bottom {
      top: $line1-height + $line2-height + $line3-height + $top-space + ($line-space * 2);
      height: $img-height -
        ($line1-height + $line2-height + $line3-height + $top-space + ($line-space * 2));
    }
  } @else {
    .thirdline-bottom {
      top: $line1-height + $line2-height + $top-space + $line-space;
      height: $img-height - ($line1-height + $line2-height + $top-space + $line-space);
    }
  }

  @if $img-width == 133px {
    @media (max-width: $bp-xs - 1px) {
      .image-right {
        left: 75px;
      }

      .firstline-top,
      .firstline-space,
      .secondline-space,
      .thirdline-bottom {
        left: 85px;
        width: calc(100% - 85px);
      }

      .firstline-right {
        width: calc(100% - 125px);
      }

      .thirdline-right {
        width: 20%;
      }
    }
  }
}
