.c-tag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: $gray;
  color: $white;
  font-size: modular-scale(-2);
  font-weight: 700;
  line-height: 1;

  &--time {
    margin-bottom: space(sm);
    padding: 0.25em 0.5em;
    border-radius: 2px;
    background-color: $black;
    color: $white;
    font-size: modular-scale(-5);
    font-weight: 400;
  }

  &--on-air {
    position: relative;
    padding: 3px 7px 3px 13px;
    text-transform: uppercase;

    .c-card--show & {
      display: inline;
      margin-left: 3px;
    }

    .l-show__header & {
      margin: 0 0 0.2em -0.4em;
    }

    &::before {
      display: block;
      margin-top: -2px;
      border-radius: 2px;
      background-color: $white;
      content: '';
      animation-name: live-status;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      @include position(absolute, 50% null null 5px);
      @include size(4px);
    }
  }
}

@keyframes live-status {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
